import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import DashboardPropertyStatsListItem
    from "@/views/AdminDashboard/AdminDashboardProperty/DashboardPropertyStatsListItem/DashboardPropertyStatsListItem/";

export default {
    name: 'DashboardPropertyStats',
    props: {
        type: {
            type: String,
            default: ''
        },
        properties: {
            type: [],
            default: []
        },
    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        DashboardPropertyStatsListItem

    },
    mounted() {
    },
    data() {
        return {
        }
    }
}